import * as React from "react";
import { logger } from "bernie-core";
import { UitkHeading, UitkHeadingProps, UitkSubheading } from "@egds/react-core/text";
import { CTACarouselRegionFlexModuleResult, CTACarouselRegionProps } from "./typings";
import { AnalyticsConfig } from "@egds/react-core/utils";
import {
  Action,
  FlexTrackingInfo,
  sendImmediateClickstreamTrackEvent,
} from "components/utility/analytics/FlexAnalyticsUtils";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";
import { IsomorphicCarousel } from "components/shared/IsomorphicCarousel/IsomorphicCarousel";
import { inject } from "mobx-react";
import { UitkSpacing, UitkSpacingProps } from "@egds/react-core/spacing";
import { hasRenderableChildren } from "src/stores/ExperienceTemplateStore/shouldRender";
import { useClickTracker } from "@shared-ui/clickstream-analytics-context";
import { TrackedVisibility } from "src/components/utility/analytics/TrackedVisibility";
import { RegionChildrenList } from "src/utils/RegionUtils";

export interface CTACarouselRegionContext {
  hasBorder?: boolean;
  carouselSize?: number;
}

const defaultContext = {
  hasBorder: false,
};

export const CTACarouselRegionContext = React.createContext<CTACarouselRegionContext>(defaultContext);

@logger("CTACarouselRegion")
@inject("analytics", "context", "flexModuleModelStore")
export class CTACarouselRegion extends React.Component<CTACarouselRegionProps> {
  public render() {
    return <CtaCarouselComponent {...this.props} />;
  }
}

const CtaCarouselComponent = (props: CTACarouselRegionProps) => {
  const { blossomComponent, flexModuleModelStore, templateComponent, l10n } = props;
  const track = useClickTracker();

  const {
    metadata: { id, name: metaDataName },
    config: { containerName },
    type,
  } = templateComponent;
  const carouselName = containerName || type;
  const model = flexModuleModelStore.getModel(id) as CTACarouselRegionFlexModuleResult;

  /* istanbul ignore next */
  if (!model) {
    return null;
  }

  const { mobileWidth } = model;
  if (
    !blossomComponent ||
    !hasRenderableChildren(templateComponent, flexModuleModelStore) ||
    mobileWidth === "hidden"
  ) {
    return null;
  }

  const { title, innerTitle, hasBorder, lg, md, sm, view } = model;

  const headingSpacing: UitkSpacingProps = { margin: { inline: "three" } };
  let subheadingSpacing: UitkSpacingProps = { margin: { inline: "three" } };
  let regionSpacing: UitkSpacingProps = { padding: { blockend: "six", blockstart: "three" } };
  let carouselSpacing: UitkSpacingProps = { padding: { blockend: "unset" }, margin: { inlinestart: "three" } };
  const moduleTypes = ["call-to-action", "native-marquee-tile", "editorial", "experience-card"];
  let showMaxItems = false;
  let itemsVisible = {
    lg: lg || 6,
    md: md || 4,
    sm: sm || 3,
  };

  if (view === "lob-carousel") {
    /* istanbul ignore next */
    itemsVisible = {
      lg: lg || 5,
      md: md || 4,
      sm: sm || 2,
    };
    subheadingSpacing = { margin: { blockstart: "unset", inline: "three" } };
    regionSpacing = { padding: { small: { blockend: "three" }, medium: { blockend: "unset" } } };
    carouselSpacing = { margin: { blockend: "unset", inlinestart: "three" } };
  }

  if (view === "grid-merch-container") {
    /* istanbul ignore next */
    itemsVisible = {
      lg: lg || 4,
      md: md || 4,
      sm: sm || 2,
    };
    showMaxItems = true;
    regionSpacing = { padding: { block: "six" } };
  }

  const className = `CTACarouselRegion ${type} `;

  /* istanbul ignore next */
  if (!templateComponent.children || templateComponent.children.length < 1) {
    return null;
  }

  const carouselSize = templateComponent.children.length;

  const keyHelper = new ItemKeyHelper(id);
  const { formatText } = l10n;
  const headingConfig: UitkHeadingProps = { tag: "h2", size: 3 };

  const analyticsId = containerName || metaDataName;
  const analyticsConfig: AnalyticsConfig = {
    id: analyticsId as string,
    callback: (moduleName, description) => {
      const action = moduleName.includes("click") ? Action.CLICK : Action.IMPRESSION;

      const flexTrackingInfo: FlexTrackingInfo = {
        moduleName,
        action,
        linkName: description,
      };
      sendImmediateClickstreamTrackEvent(flexTrackingInfo, track);
    },
  };

  templateComponent.children = templateComponent.children.filter((child) => moduleTypes.includes(child.type!));

  return (
    <UitkSpacing {...regionSpacing}>
      <div className={className} data-testid="cta-carousel-region" id={id}>
        <TrackedVisibility
          rfrrId={metaDataName || "CTACarouselRegion"}
          moduleName={metaDataName || "CTACarouselRegion"}
        >
          {title && (
            <UitkSpacing {...headingSpacing}>
              <UitkHeading {...headingConfig}>{title}</UitkHeading>
            </UitkSpacing>
          )}
          {innerTitle && (
            <UitkSpacing {...subheadingSpacing}>
              <UitkSubheading tag="p">{innerTitle}</UitkSubheading>
            </UitkSpacing>
          )}
          <CTACarouselRegionContext.Provider value={{ hasBorder, carouselSize }}>
            <UitkSpacing {...carouselSpacing}>
              <IsomorphicCarousel
                itemsVisible={itemsVisible}
                buttonText={{
                  nextButton: formatText("carousel.item.next"),
                  prevButton: formatText("carousel.item.prev"),
                }}
                pageBy={1}
                peek
                showMaxItems={showMaxItems}
                carouselName={carouselName}
                analytics={analyticsConfig}
              >
                {templateComponent.children
                  .filter((item) => !item.config.empty)
                  ?.map((child, index) => (
                    <div key={keyHelper.next()} data-idx={index}>
                      <RegionChildrenList templateComponents={[child]} blossomComponent={blossomComponent} />
                    </div>
                  ))}
              </IsomorphicCarousel>
            </UitkSpacing>
          </CTACarouselRegionContext.Provider>
        </TrackedVisibility>
      </div>
    </UitkSpacing>
  );
};

export default CTACarouselRegion;
